import React from "react";
import styled, { keyframes } from "styled-components";

const scrollLeft = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const scrollRight = keyframes`
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0); }
`;

const TickerWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  background-color: #000;
  width: 100%;
`;

interface TickerContainerProps {
  direction: string;
  duration: number;
}

const TickerContainer = styled.div<TickerContainerProps>`
  display: inline-flex;
  align-items: center;
  animation: ${({ direction }) =>
      direction === "left" ? scrollLeft : scrollRight}
    ${(props) => props.duration}s linear infinite;
  padding: 1rem 0;
`;

const TextContent = styled.span`
  margin-right: 3rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
`;

type TickerTextProps = {
  text?: string;
  direction?: "left" | "right";
};

const TickerText: React.FC<TickerTextProps> = ({
  text = "Build Your Own Space Satellite",
  direction = "left",
}) => {
  const [repeatCount, setRepeatCount] = React.useState(3); // 기본값 설정
  const [duration, setDuration] = React.useState(20); // 기본 애니메이션 시간
  const containerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (containerRef.current && contentRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const contentWidth = contentRef.current.offsetWidth;

      // 필요한 반복 횟수 계산 (최소 2개 이상)
      const calculated = Math.max(
        Math.ceil((containerWidth * 2) / contentWidth),
        2
      );
      setRepeatCount(calculated);

      const calculatedDuration = Math.max(contentWidth * 0.1, 10);
      setDuration(calculatedDuration);
    }
  }, [text]);

  return (
    <TickerWrapper ref={containerRef}>
      <TickerContainer direction={direction} duration={duration}>
        <TextContent ref={contentRef}>{text}</TextContent>
        {[...Array(repeatCount)].map((_, i) => (
          <TextContent key={i}>{text}</TextContent>
        ))}
      </TickerContainer>
    </TickerWrapper>
  );
};

export default TickerText;
