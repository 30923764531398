// src/components/LoadingScreen.tsx
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import logo from "../assets/images/MIRA_LOGO_W1.png";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const LoadingContainer = styled.div<{ isHidden: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 100;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  visibility: ${({ isHidden }) => (isHidden ? "hidden" : "visible")};
  transition:
    opacity 1s ease-in-out,
    visibility 1s ease-in-out;
`;

const LogoImage = styled.img`
  width: 150px;
  animation: ${fadeIn} 1s forwards ease-in-out;
`;

const LoadingScreen: React.FC<{ onLoaded: () => void }> = ({ onLoaded }) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (isHidden) {
      onLoaded(); // 컴포넌트를 숨길 때 onLoaded 콜백 호출
    }
  }, [isHidden, onLoaded]);

  useEffect(() => {
    const timer = setTimeout(() => setIsHidden(true), 1500); // 예시: 1.5초 후 숨김
    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadingContainer isHidden={isHidden}>
      <LogoImage src={logo} alt="Loading Logo" />
    </LoadingContainer>
  );
};

export default LoadingScreen;
