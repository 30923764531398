import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../assets/images/MIRA_LOGO_W1.png";

import { FaLinkedinIn, FaYoutube, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

// const FooterContainer = styled.footer`
//   background-color: #000000;
//   color: rgb(122, 122, 122);
//   padding: 2rem 2rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   z-index: 999;
//   position: relative;
//   overflow: hidden;

//   &::after {
//     content: "";
//     position: absolute;
//     bottom: -800px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 1600px;
//     height: 1600px;
//     background: radial-gradient(circle, #00f, #000000 70%, transparent 100%);
//     z-index: -1;
//     border-radius: 80%;
//   }

//   @media (max-width: 768px) {
//     &::after {
//       width: 300px;
//       height: 300px;
//     }
//   }

//   @media (max-width: 427px) {
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//   }
// `;

const FooterContainer = styled.footer`
  background-color: #000000;
  color: rgb(122, 122, 122);
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  border-top: 1px solid #414141;

  @media (max-width: 427px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin-top: 5%;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 768px) {
    text-align: center;
    justify-content: center;
  }

  @media (max-width: 427px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  margin: 1rem;
  min-width: 150px;
  max-width: 200px;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 425px) {
    margin: 0rem;
    margin-bottom: 0rem;
  }

  h4 {
    margin: 0;
  }
`;

const Text = styled.h1`
  color: white;
  text-transform: uppercase;
  text-align: center;
  /* margin-bottom: 1rem; */
  font-size: 1.3rem;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.5rem;
  }
`;
const Logo = styled.img`
  width: 40%;
  height: auto;

  @media (max-width: 768px) {
    min-width: 500px;
  }
  @media (max-width: 425px) {
    min-width: 300px;
  }
`;

const FooterLinks = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #ccc;
    }
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;

  a {
    color: #fff;
    margin-right: 1rem;
    font-size: 1.5rem;
    text-decoration: none;

    &:hover {
      color: #ccc;
    }
  }

  @media (max-width: 609px) {
    justify-content: center;
  }
`;

const FooterBottom = styled.div`
  border-top: 1px solid #333;
  padding-top: 1rem;
  text-align: center;
  width: 100%;
  max-width: 1200px;
  font-size: 10px;
`;

const Subscribe = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  justify-content: space-around;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: block;
    text-align: center;

    width: 90%;
  }
`;

const Bar = styled.br`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SubscribeLeft = styled.div`
  p {
    padding-top: 40px;
    font-size: 1rem;
    @media (max-width: 768px) {
      font-size: 0.8rem;
      padding-top: 10px;
    }
  }
`;

const SubscribeRight = styled.div``;

const EmailInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  width: 200px;

  @media (max-width: 768px) {
    width: 90%;
    margin-right: 0px;
  }

  @media (max-width: 425px) {
    text-align: center;
    padding: 10px 0;

    &::placeholder {
      text-align: center;
    }
  }
`;

const SubscribeButton = styled.button<{ disabled: boolean }>`
  padding: 10px 20px;
  font-size: 16px;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#ff4500")};
  color: ${(props) => (props.disabled ? "#666" : "#fff")};
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => !props.disabled && "#ff5714"};
  }

  @media (max-width: 834px) {
    text-align: center;
    margin-top: 20px;
  }
`;

const Footer: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmail(email);
    setIsValidEmail(validateEmail(email));
  };

  const handleSubscribe = async () => {
    if (isValidEmail) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/subscribe`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );

        if (response.ok) {
          alert("Subscription successful!");
          setEmail("");
          setIsValidEmail(false);
        } else {
          alert("Subscription failed. Please try again.");
        }
      } catch (error) {
        alert("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <FooterContainer>
      <Logo src={logo} alt="Mira Space Logo" />
      <Text>Shaping the future of space through education</Text>
      <FooterTop>
        {/* <FooterSection>
          <Logo src={logo} alt="Mira Space Logo" />
        </FooterSection> */}
        <FooterSection>
          <h4>Quick Links</h4>
          <FooterLinks>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/explore">Explore</Link>
            </li>
            <li>
              <Link to="/news&events">News & Events</Link>
            </li>
          </FooterLinks>
        </FooterSection>
        <FooterSection>
          <h4>Services</h4>
          <FooterLinks>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/satellites-kit">Satellite Kit</Link>
            </li>
            <li>
              <Link to="/mission-support">Mission Support</Link>
            </li>
          </FooterLinks>
        </FooterSection>
        <FooterSection>
          <h4>General</h4>
          <FooterLinks>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <a href="mailto:support@miraspace.ai">support@miraspace.ai</a>
          </FooterLinks>
        </FooterSection>
        <FooterSection>
          <h4>Follow Us On</h4>
          <SocialMediaIcons>
            <a
              href="https://www.instagram.com/miraspace.ai"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/mira-space"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn />
            </a>
            <a href="https://x.com/MiraSpace_ai" aria-label="X">
              <FaXTwitter />
            </a>
            <a href="https://www.youtube.com" aria-label="YouTube">
              <FaYoutube />
            </a>
          </SocialMediaIcons>
        </FooterSection>
      </FooterTop>

      {/* <Subscribe>
        <SubscribeLeft>
          <p>
            Tomorrow’s Technology,&nbsp;
            <Bar />
            Today’s Education.
          </p>
        </SubscribeLeft>
        <SubscribeRight>
          <h3>Subscribe to Mira Space</h3>
          <EmailInput
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleChange}
          />
          <SubscribeButton onClick={handleSubscribe} disabled={!isValidEmail}>
            Subscribe
          </SubscribeButton>
        </SubscribeRight>
      </Subscribe> */}

      <FooterBottom>
        <p>
          - --- -- --- .-. .-. --- .-- .----. ... - . -.-. .... -. --- .-.. ---
          --. -.-- --..-- - --- -.. .- -.-- .----. ... . -.. ..- -.-. .- - ..
          --- -.
        </p>
        <p>
          &copy; {new Date().getFullYear()} Mira Space Inc. All Rights Reserved.
        </p>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
