import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Partners from "./Partners";
import Products from "./Products";
import logo from "../assets/images/MIRA_LOGO_SYMBOL.png";
import Img1 from "../assets/images/Bottom.png";
import EducatorsPage from "./EducatorsPage";
import { motion } from "framer-motion";
import EducationalPrograms from "./EducationalPrograms";
import JoinUs from "./JoinUs";
import TestimonialsCaseStudies from "./TestimonialsCaseStudies";
import NewsMedia from "./NewsMedia";

const HomeSec = styled.div`
  background-color: black;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.7);
`;

const Content = styled(motion.div)`
  text-align: right;
  color: white;
  padding: 15% 10% 0 0;

  @media (max-width: 768px) {
    padding: 0 5%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const BannerText = styled.h1`
  font-size: 4.5em;
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: 20px;
  background: linear-gradient(120deg, #ffffff, #a8a8a8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 3em;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  justify-content: center;
  color: white;
  padding: 80px 120px;
  min-height: 100vh;
  gap: 80px;

  @media (max-width: 768px) {
    padding: 40px 20px;
    gap: 40px;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(${Img1});
    background-size: cover;
    background-position: center;
  }
`;

const TextContainer = styled.div`
  max-width: 600px;
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 30px;
    border-radius: 15px;
    backdrop-filter: blur(10px);
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 30px;
  letter-spacing: -1px;

  @media (max-width: 768px) {
    font-size: 3.5rem;
  }
`;

const Paragraph = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 40px;
  word-break: keep-all;

  hr {
    width: 300px;
    margin: 40px auto;
    border: none;
    height: 1px;
    background: linear-gradient(90deg, transparent, white, transparent);
  }

  @media (max-width: 768px) {
    font-size: 14px;

    hr {
      width: 300px;
      margin: 30px auto;
    }
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 500px;
  opacity: 0;
  animation: slideIn 1s ease-in forwards 0.5s;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 10%;
  height: auto;
  margin-bottom: 40px;
  transition: transform 0.3s ease;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 140px;
    margin-bottom: 30px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    /* transform: translateY(-10px); */
  }
`;

const MoreLink = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: grey;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
// eslint-disable-next-line
const ValuesSection = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 4rem 2rem;
  color: white;
  text-align: center;

  @media (max-width: 425px) {
    padding: 4rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
// eslint-disable-next-line
const ValuesTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  background: linear-gradient(to right, #ff4500, #ff9513, #ff1361, #311040);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;
// eslint-disable-next-line
const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
// eslint-disable-next-line
const ValueCard = styled.div`
  flex: 1 1 30%;
  background-color: #222;
  padding: 2rem;
  border-radius: 10px;
  min-width: 280px;
  text-align: left;

  text-align: center;
  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #ff4500;

    @media (max-width: 480px) {
      font-size: 1.3rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.6;

    @media (max-width: 480px) {
      font-size: 0.9rem;
      line-height: 1.4;
    }
  }
  @media (max-width: 375px) {
    padding: 0.7rem;
    min-width: 180px;
  }
`;

const BannerSection = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;
// eslint-disable-next-line
const ProductsText = styled.h2`
  margin-top: -30px;
  padding-top: 160px;
  color: white;
  /* font-size: 5rem; */
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const Home: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75;
    }
  }, []);

  return (
    <>
      <BannerSection>
        <VideoBackground
          ref={videoRef}
          src="/assets/bg.mp4"
          muted
          autoPlay
          loop
          playsInline
        />
        <Content
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <BannerText>
            SHAPING THE FUTURE OF
            <br />
            SPACE THROUGH EDUCATION
          </BannerText>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            style={{ fontSize: "1.2em", color: "#cccccc" }}
          >
            미래를 선도하는 우주기술 교육
          </motion.p>
        </Content>
      </BannerSection>
      {/* <Spacer /> */}
      <Container>
        <TextContainer>
          <Title>About Us</Title>
          <StyledLogo src={logo} alt="Mira Space Logo" />
          <Paragraph>
            Mira Space is leading the future of satellite education through
            innovative satellite kits and hands-on training curricula.
            <br />
            <br /> Aiming to nurture the next generation of space professionals,
            Mira Space provides satellite technology education aligned with the
            New Space era, establishing a lasting heritage in space training.
            Through cutting-edge satellite kits and global partnerships, Mira
            Space is opening new horizons in space technology education and
            building a future full of greater possibilities.
            <br />
            <br />
            <hr />
            <br />
            미라스페이스는 혁신적인 위성 키트와 실습형 교육 커리큘럼을 통해
            <br /> 위성 교육의 미래를 선도하고 있습니다.
            <br />
            <br />
            미라스페이스는 차세대 우주 전문가 양성을 목표로,
            <br /> New Space 시대에 발맞춘 위성 기술 교육을 통해 헤리티지를
            제공합니다. <br />
            혁신적인 위성 키트와 글로벌 파트너십을 통해 우주 기술 교육의 새
            지평을 열어가며, <br /> 더 큰 가능성의 미래를 만들어갑니다.
            <br />
            <br />
            <MoreLink href="/about">Read More →</MoreLink>
          </Paragraph>
        </TextContainer>
        <ImageContainer>
          <StyledImage src={Img1} alt="Mira Space Img" />
        </ImageContainer>
      </Container>
      {/* <TestimonialsCaseStudies /> */}
      <HomeSec>
        <EducatorsPage />
        <Products />
        {/* <EducationalPrograms /> */}
        <Partners />
        {/* <JoinUs /> */}
        {/* <NewsMedia /> */}
      </HomeSec>
    </>
  );
};

export default Home;
