// App.tsx
import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styled from "styled-components";
import ProductsDetails from "./pages/ProductsDetails";
import ScrollToTop from "./pages/ScrollToTop";
import LoadingScreen from "./components/LoadingScreen";

const AppContainer = styled.div<{ isLoading: boolean }>`
  overflow-x: hidden;
  background-color: ${({ isLoading }) => (isLoading ? "black" : "transparent")};
  transition: background-color 1s ease;
`;

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const handleLoaded = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [location.pathname]);

  return (
    <AppContainer isLoading={isLoading}>
      {isLoading && <LoadingScreen onLoaded={handleLoaded} />}
      {!isLoading && (
        <>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/products" element={<ProductsDetails />} />
          </Routes>
          <Footer />
        </>
      )}
    </AppContainer>
  );
};

export default App;
