import React from "react";
import styled from "styled-components";
// import OrionSpace from "../assets/images/Partners/OrionSpace.png";
// import InnoSpace from "../assets/images/Partners/InnoSpace.png";

const PartnersContainer = styled.div`
  padding: 4rem 2rem;
  background-color: #000000;
  text-align: center;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 2rem;
`;

const PartnerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
`;

const PartnerCard = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
`;

const PartnerLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px; /* 고정 높이 설정 */
`;

const PartnerLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* 이미지를 크기에 맞춰서 비율을 유지하며 축소 */
`;

const PartnerName = styled.h3`
  font-size: 1.5rem;
  color: #555;
  margin: 1rem 0;
`;

const PartnerDescription = styled.p`
  font-size: 1rem;
  color: #777;
`;

const PartnerLink = styled.a`
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #373737;
  }
`;

const Partners: React.FC = () => {
  const partners = [
    {
      id: 1,
      name: "Orion Space",
      description:
        "Orion Space is a satellite company in Nepal and the leading provider of satellite education in the country.",
      logo: "/assets/Partners/OrionSpace.png",
      link: "https://orionspace.com.np/",
    },
    // {
    //   id: 2,
    //   name: "Innospace",
    //   description: "Korean launch service provider for space missions.",
    //   logo: "/assets/Partners/InnoSpace.png",
    //   link: "https://www.innospc.com/",
    // },
    {
      id: 3,
      name: "HereCreative",
      description:
        "Here Creative is a creative agency that helps brands create visually stunning experiences through art and innovative media technology.",
      logo: "/assets/Partners/Here_black.png",
      link: "https://www.herecreative.co/",
    },
  ];

  return (
    <PartnersContainer>
      <Title>Our Partners</Title>
      <PartnerList>
        {partners.map((partner) => (
          <PartnerCard key={partner.id}>
            <PartnerLogoWrapper>
              <PartnerLogo src={partner.logo} alt={partner.name} />
            </PartnerLogoWrapper>
            <PartnerName>{partner.name}</PartnerName>
            <PartnerDescription>{partner.description}</PartnerDescription>
            <PartnerLink
              href={partner.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Website
            </PartnerLink>
          </PartnerCard>
        ))}
      </PartnerList>
    </PartnersContainer>
  );
};

export default Partners;
