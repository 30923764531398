import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import MiraLaunchPad from "../assets/Products/LaunchPad.png";
import MiraLiftOff from "../assets/Products/LiftOff.png";
import MiraMaxQ from "../assets/Products/MaxQ.jpeg";
import MiraMECO from "../assets/Products/MECO.png";
import MiraOrbit from "../assets/Products/Orbit.png";
import Earth from "../assets/images/back/Earth.jpg";

const FullScreenBanner = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${Earth});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 25px;
    word-break: keep-all;
    overflow-wrap: keep-all;
  }
`;

const ProductsPage = styled.div`
  background-color: #000000;
  color: #ffffff;
  position: relative;
  padding-bottom: 50px;
`;

const ProductSection = styled.div<{ reverse?: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  gap: 2rem;
  padding: 2rem 0;
  margin: 5rem;
  border-bottom: 1px solid #444;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 3rem;
  }
`;

const ProductImage = styled.img`
  width: 500px;
  height: 500px;
  border-radius: 8px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ProductTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
`;

const ProductPrice = styled.p`
  font-size: 1.3rem;
  color: #fe5113;
`;

const ProductDescription = styled.p<{ expanded: boolean }>`
  font-size: 1rem;
  color: #cccccc;
  line-height: 1.6;
  max-height: ${(props) => (props.expanded ? "500px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease;

  white-space: pre-line;
  line-height: 1.6;

  word-break: keep-all;
  overflow-wrap: keep-all;
`;

const ToggleButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #444;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #666;
  }
`;

const ScrollProgressContainer = styled.div<{
  isVisible: boolean;
  isFixed: boolean;
}>`
  position: ${({ isFixed }) => (isFixed ? "fixed" : "absolute")};
  bottom: ${({ isFixed }) => (isFixed ? "0" : "auto")};
  width: 100%;
  height: 35px;
  margin-top: -33px;
  display: flex;
  align-items: center;
  background-color: #000000;
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0)" : "translateY(10px)"};
`;

const ScrollBarLabel = styled.div<{ active: boolean }>`
  color: ${({ active }) => (active ? "#ffffff" : "#777777")};
  flex: 1;
  text-align: center;
  font-size: 1rem;
  position: relative;
  z-index: 1;

  span {
    background-color: #000000;
    font-size: 10px;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    position: relative;
    z-index: 2;
    display: inline-block;
    top: -3px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 0.5px;
    background-color: #777777;
    transform: translateY(-50%);
    z-index: 0;
  }
`;

const ScrollBarIndicator = styled.div<{ progress: number }>`
  position: absolute;
  left: 0;
  height: 1px;
  width: ${({ progress }) => progress}%;
  background-color: #ffffff;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.3s ease;
  z-index: 0;
`;

const ProductDetailDescription = styled.p``;

const productsData = [
  {
    imgSrc: MiraLaunchPad,
    maintitle: "Mira LaunchPad",
    title: " LaunchPad ",
    price: "Coming Soon",
    description:
      "The foundational stage for aspiring space enthusiasts ready to launch.",
    descriptionKr:
      "우주를 꿈꾸는 이들을 위한 기초적 발판이 되어주는 제품입니다.",
    details:
      "Mira LaunchPad is an introductory educational kit designed to help elementary and middle school students understand the fundamentals of space technology. By using the ESP32 microcontroller, students can learn the basics of electronics and control sensors, as well as build skills in data collection and analysis. This kit is ideal for developing foundational programming knowledge needed for device control, allowing students to operate and receive feedback from their equipment independently.",
    detailsKr:
      "Mira LaunchPad는 초, 중등 학생들이 우주 기술의 기본 개념을 이해할 수 있도록 설계된 입문 교육 키트입니다. ESP32 마이크로컨트롤러를 이용해 간단한 전자공학 원리와 센서 제어 방법을 배우며, 이를 통해 데이터 수집 및 분석 능력을 키우게 됩니다. 이 키트는 전자기기 제어에 필요한 기초 프로그래밍 지식을 쌓는 데 이상적이며, 학생들이 스스로 장비를 조작하고 피드백을 얻어가며 학습할 수 있도록 돕습니다.",
    Platform: "Platform :",

    parts: "",
  },
  {
    imgSrc: MiraLiftOff,
    maintitle: "Mira LiftOff",
    title: " LiftOff ",
    price: "Coming Soon",
    description:
      "For those ready to ascend, LiftOff guides you beyond the fundamentals.",
    descriptionKr:
      "기초를 넘어서고자 하는 이를 위해 LiftOff가 더 높은 단계로 이끌어줍니다.",
    details:
      "Mira LiftOff is tailored for middle and high school students, enabling them to explore more complex electronic systems and communication technologies through Arduino. This educational kit allows students to engage in various projects that introduce them to the practical applications of space technology. By working with sensor data processing and communication systems, students acquire the skills to apply basic space technology concepts to real-world projects.",
    detailsKr:
      "Mira LiftOff는 중고등학생들이 Arduino를 사용해 좀 더 복잡한 전자 시스템과 통신 기술을 탐구할 수 있도록 구성된 교육 키트입니다. 이 키트를 통해 학생들은 다양한 프로젝트를 수행하며 우주 기술의 응용을 경험할 수 있습니다. 실습을 통해 센서 데이터를 처리하고 통신 시스템을 구성하는 방법을 학습함으로써, 기본적인 우주 기술 프로젝트에 응용할 수 있는 능력을 갖추게 됩니다.",
  },
  {
    imgSrc: MiraMECO,
    maintitle: "Mira MaxQ",
    title: " MaxQ ",
    price: "Coming Soon",
    description:
      "An advanced level for those who are ready to handle the dynamics of space technology.",
    descriptionKr:
      "우주 기술의 역동성을 다룰 준비가 된 이들을 위한 고급 레벨입니다.",
    details:
      "Mira MaxQ is a kit designed for high school students and above, especially those with a deep interest in space technology. This advanced educational kit uses the STM32 microcontroller to teach in-depth electronic system design. Through hands-on experience with complex embedded systems, students can improve their programming and circuit design skills while learning to consider durability and reliability, which are essential in real space environments.",
    detailsKr:
      "Mira MaxQ는 고등학생 이상으로, 우주 기술에 깊은 관심을 가진 학습자들을 위한 심화 교육 키트입니다. 이 키트는 STM32 마이크로컨트롤러를 활용해 고급 전자 시스템 설계를 배우는 데 중점을 두고 있습니다. 학생들은 복잡한 임베디드 시스템 개발을 경험하면서 더 높은 수준의 프로그래밍과 회로 설계 능력을 배양할 수 있으며, 이를 통해 실제 우주 환경에서의 시스템 내구성과 안정성을 고려하는 실무적인 경험을 쌓게 됩니다.",
  },
  {
    imgSrc: MiraMECO,
    maintitle: "Mira MECO",
    title: " MECO ",
    price: "Coming Soon",
    description:
      "The stage where you master control and precision in space operations.",
    descriptionKr:
      "정밀성과 제어를 익히며 우주 조작의 고수로 나아가는 단계입니다.",
    details:
      "Mira MECO is an advanced educational kit for university students and adults, utilizing both Arduino and STM32 for a comprehensive learning experience that spans from the basics to advanced applications of space technology. With this kit, students can work on sophisticated projects, gaining expertise in processor control, real-time data analysis, and advanced communication systems. The experience prepares students to understand and work on various space technology projects.",
    detailsKr:
      "Mira MECO는 대학생 및 성인을 대상으로 한 고급 교육 키트로, Arduino와 STM32를 활용해 복합적인 전자 시스템 설계와 우주 기술의 기초부터 심화 응용까지를 폭넓게 다룹니다. 이 키트는 심화된 프로젝트 수행을 통해 프로세서 제어와 실시간 데이터 분석, 고급 통신 기술까지 학습할 수 있도록 설계되었습니다. 학생들은 다양한 우주 기술 프로젝트를 경험하며 실제 우주 탐사와 관련된 기술을 이해하게 됩니다.",
  },
  {
    imgSrc: MiraOrbit,
    maintitle: "Mira Orbit",
    title: " Orbit ",
    price: "Coming Soon",
    description:
      "The final step—Orbit lets you operate at the highest level, simulating real satellite orbit control.",
    descriptionKr:
      "마지막 단계로, Mira Orbit은 위성 궤도 제어를 현실적으로 모의 실습합니다.",
    details:
      "Mira Orbit is a high-level educational kit intended for university students and above who wish to gain a deep understanding of space technology. Utilizing the STM32 microcontroller, this kit covers advanced embedded systems and satellite control technology. Through a blend of theory and practical experience, students learn to manage satellite orbital positioning and control systems, equipping them to keep pace with the latest trends in space technology and industry developments.",
    detailsKr:
      "Mira Orbit는 우주 기술에 대한 깊은 이해를 원하는 대학생 이상을 위한 심화 교육 키트입니다. STM32 마이크로컨트롤러를 활용하여 고급 임베디드 시스템과 위성 제어 기술을 학습하며, 이론과 실습을 통해 최신 우주 기술 트렌드를 파악할 수 있습니다. 학생들은 실제 위성의 궤도 진입과 위치 제어 시스템을 설계하고 운영할 수 있는 능력을 배양하여, 우주산업의 최신 기술 흐름을 선도하는 준비를 하게 됩니다.",
  },
];

const Products = () => {
  const [expandedProduct, setExpandedProduct] = useState<number | null>(null);
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isFixed, setIsFixed] = useState(true);
  const orbitSectionRef = useRef<HTMLDivElement | null>(null);

  const toggleExpand = (index: number) => {
    setExpandedProduct(index === expandedProduct ? null : index);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const totalHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      if (orbitSectionRef.current) {
        const orbitBottom =
          orbitSectionRef.current.getBoundingClientRect().bottom +
          window.scrollY;
        const maxProgress = orbitBottom - window.innerHeight;
        const newProgress = Math.min((scrollPosition / maxProgress) * 100, 100);
        setProgress(newProgress);
      } else {
        setProgress((scrollPosition / totalHeight) * 100);
      }

      setIsVisible(scrollPosition > 0);

      if (orbitSectionRef.current) {
        const orbitBottom =
          orbitSectionRef.current.getBoundingClientRect().bottom;
        setIsFixed(orbitBottom > window.innerHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ProductsPage>
      <FullScreenBanner>
        나만의 인공위성을 만들고 <br />
        끝없는 우주의 미지로 한 걸음 더 도전하세요.
        <br /> 새로운 가능성이 당신을 기다립니다.
      </FullScreenBanner>

      {productsData.map((product, index) => (
        <ProductSection
          key={index}
          reverse={index % 2 === 1}
          ref={index === productsData.length - 1 ? orbitSectionRef : undefined}
        >
          <ProductImage src={product.imgSrc} alt={product.title} />
          <ProductDetails>
            <ProductTitle>{product.maintitle}</ProductTitle>
            <ProductPrice>{product.price}</ProductPrice>
            <ProductDetailDescription>
              <b>{product.description}</b>
              <br />
              <br />
              {product.details}
              <br />
              <br />
              <hr />
              <br />
              <b>{product.descriptionKr}</b>
              <br />
              <br />
              {product.detailsKr}
            </ProductDetailDescription>
            <ProductDescription expanded={expandedProduct === index}>
              {expandedProduct === index && <span></span>}
            </ProductDescription>
            {/* <ToggleButton onClick={() => toggleExpand(index)}>
              {expandedProduct === index ? "Show Less" : "Show More"}
            </ToggleButton> */}
          </ProductDetails>
        </ProductSection>
      ))}

      <ScrollProgressContainer isVisible={isVisible} isFixed={isFixed}>
        {productsData.map((product, index) => (
          <ScrollBarLabel key={index} active={progress > index * 20}>
            <span>{product.title}</span>
          </ScrollBarLabel>
        ))}
        <ScrollBarIndicator progress={progress} />
      </ScrollProgressContainer>
    </ProductsPage>
  );
};

export default Products;
