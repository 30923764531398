import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import logo from "../assets/images/MIRA_LOGO_W1.png";
import { FaLinkedinIn, FaYoutube, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const GlobalStyle = createGlobalStyle<{ menuOpen: boolean }>`
  body {
    overflow: ${({ menuOpen }) => (menuOpen ? "hidden" : "auto")};
  }
`;

const HeaderContainer = styled.header<{
  isScrolled: boolean;
  menuOpen: boolean;
}>`
  position: fixed;
  top: 0;
  z-index: 50;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  height: 4rem;
  background-color: ${({ isScrolled, menuOpen }) =>
    menuOpen || isScrolled ? "black" : "transparent"};
  transition:
    background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.15s ease;

  @media (max-width: 768px) {
    padding: 1rem 0.5rem 1rem 3rem;
  }
`;

const Logo = styled.img`
  height: 1rem;
`;

const NavLinks = styled.nav<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  align-items: center;
  position: fixed;
  top: 4rem;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0)" : "translateY(-20px)"};
  transition:
    opacity 0.4s ease,
    transform 0.4s ease;
  z-index: 49;

  a {
    color: white;
    margin: 1rem 0;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;

    &:hover {
      color: #f0f0f0;
    }
  }

  @media (min-width: 769px) {
    position: static;
    display: flex;
    flex-direction: row;
    height: auto;
    opacity: 1;
    transform: none;
    background-color: transparent;

    a {
      font-size: 1rem;
      margin-left: 2rem;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 0.5rem;

  a {
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;

    &:hover {
      color: #ccc;
    }
  }

  @media (max-width: 609px) {
    justify-content: center;
    a {
      align-items: center;
      margin: 0 auto;
      padding: 1rem;
    }
  }
`;

const HamburgerButton = styled.button<{ isOpen: boolean }>`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 51;

  @media (max-width: 768px) {
    display: block;
  }

  div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px;
    transition: 0.4s;
    transform-origin: center;

    &:nth-child(1) {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(45deg) translateY(11px)" : "rotate(0)"};
    }
    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }
    &:nth-child(3) {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(-45deg) translateY(-11px)" : "rotate(0)"};
    }
  }
`;

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMenuOpen(false); // 768px 이상일 때 메뉴 닫기
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize); // resize 이벤트 추가

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <GlobalStyle menuOpen={menuOpen} />
      <HeaderContainer isScrolled={isScrolled} menuOpen={menuOpen}>
        <Link to="/">
          <Logo src={logo} alt="Mira Space Logo" />
        </Link>

        <HamburgerButton onClick={toggleMenu} isOpen={menuOpen}>
          <div />
          <div />
          <div />
        </HamburgerButton>

        <NavLinks isOpen={menuOpen}>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            Home
          </Link>
          <Link to="/about" onClick={() => setMenuOpen(false)}>
            About
          </Link>
          <Link to="/products" onClick={() => setMenuOpen(false)}>
            Products
          </Link>
          <SocialMediaIcons>
            <a
              href="https://www.instagram.com/miraspace.ai"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/mira-space"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn />
            </a>
            <a href="https://x.com/MiraSpace_ai" aria-label="X">
              <FaXTwitter />
            </a>
            <a href="https://www.youtube.com" aria-label="YouTube">
              <FaYoutube />
            </a>
          </SocialMediaIcons>
        </NavLinks>
      </HeaderContainer>
    </>
  );
};

export default Header;
