import React from "react";
import styled from "styled-components";

const NotFoundContainer = styled.div`
  padding: 20px;
  color: white;
`;

const NotFound: React.FC = () => {
  return (
    <NotFoundContainer>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </NotFoundContainer>
  );
};

export default NotFound;
