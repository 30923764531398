import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  FaChalkboardTeacher,
  FaBook,
  FaGraduationCap,
  FaUsers,
} from "react-icons/fa";
import Moon2 from "../assets/images/back/Moon2.jpg";

const PageContainer = styled(motion.div)`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.95)),
    url(${Moon2});
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: #ffffff;
  padding: 6rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled(motion.div)`
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 95%;
    padding: 0 1rem;
  }
`;

const HeaderSection = styled(motion.div)`
  text-align: center;
  margin-bottom: 6rem;
`;

const Title = styled(motion.h1)`
  font-size: 3.5rem;
  font-weight: 800;
  background: linear-gradient(120deg, #ffffff, #a8a8a8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: #a8a8a8;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SectionContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
  }
`;

const Section = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.03);
  border-radius: 24px;
  padding: 2.5rem 2rem;
  transition: all 0.4s ease;
  text-align: center;
  overflow: hidden;
  position: relative;
  /* height: 500px; */
  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
  }

  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.05);
  }

  @media (max-width: 1200px) {
    padding: 2.2rem 1.8rem;
    /* height: 520px; */
  }

  @media (max-width: 768px) {
    padding: 2rem 1.5rem;
    /* height: 450px; */
  }
`;

const IconWrapper = styled.div`
  font-size: 2.2rem;
  color: #ffffff;
  margin-bottom: 1rem;
  opacity: 0.9;
  transition: all 0.3s ease;

  ${Section}:hover & {
    transform: scale(1.1);
    opacity: 1;
  }
`;

const SectionTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  color: #ffffff;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  white-space: pre-line;

  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, #fff, transparent);
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  ${Section}:hover &::after {
    width: 80%;
  }
`;

const Text = styled.p`
  font-size: 0.8rem;
  line-height: 1.7;
  color: #a8a8a8;
  transition: all 0.3s ease;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:not(.korean) {
    height: 400px; // 영어 텍스트 높이 고정
    margin-bottom: 1rem;
  }

  &.korean {
    font-size: 0.8rem;
    color: #9ca3af;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  ${Section}:hover & {
    color: #ffffff;
  }

  @media (max-width: 1200px) {
    font-size: 0.8rem;

    &.korean {
      font-size: 0.8rem;
    }
  }
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.6 } },
};

const headerVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const sectionVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, delay: custom * 0.1 },
  }),
};

const EducatorsPage = () => {
  return (
    <PageContainer
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <ContentWrapper>
        <HeaderSection variants={headerVariants}>
          <Title>Calling ALL Educators!</Title>
          <Subtitle>
            The Mira Space course empowers educators to introduce students to
            the wonders of space technology, fostering critical thinking and
            hands-on skills.
          </Subtitle>
        </HeaderSection>

        <SectionContainer>
          {[
            {
              icon: <FaChalkboardTeacher />,
              title: "Comprehensive\nCurriculum",
              text: "Encompasses fundamental topics from the intricacies of satellite design to hands-on assembly, providing a complete and immersive learning journey that bridges theory and practice.",
              koreanText:
                "위성 설계의 정밀한 과정부터 실습 조립까지 필수적인 주제를 모두 다루며, 이론과 실습을 연결하는 완전하고 몰입감 있는 학습 여정을 제공합니다.",
            },
            {
              icon: <FaBook />,
              title: "Professional\nResources",
              text: "Provides comprehensive lesson plans, in-depth video tutorials, and expertly crafted educator guides, offering educators the tools they need to deliver impactful and engaging learning experiences.",
              koreanText:
                "효과적이고 몰입감 있는 교육을 제공할 수 있도록, 교사들이 필요로 하는 모든 도구를 갖춘 포괄적인 수업 계획, 심층적인 비디오 튜토리얼, 전문적으로 구성된 교사용 가이드를 제공합니다.",
            },
            {
              icon: <FaGraduationCap />,
              title: "Learning\nOutcomes",
              text: "Equips learners with essential skills in problem-solving, collaboration, and critical analysis through immersive, real-world applications, empowering them to tackle challenges and drive innovation.",
              koreanText:
                "실제 응용을 통해 문제 해결, 협업, 그리고 비판적 분석과 같은 필수 능력을 배양하여, 복잡한 문제에 도전하고 혁신을 선도할 수 있는 역량을 갖추게 합니다.",
            },
            {
              icon: <FaUsers />,
              title: "For Future\nInnovators",
              text: "Tailored for a broad audience, from primary school students to university graduates and beyond, with five distinct educational levels, encouraging exploration in space & STEM fields.",
              koreanText:
                "초등학생부터 고등학생, 대학(원)생 이상, 관심 있는 모든 일반인을 위한 5단계의 교육 수준으로 구성되어 있으며, 우주와 STEM 분야의 탐구를 장려합니다.",
            },
          ].map((section, index) => (
            <Section key={index} variants={sectionVariants} custom={index}>
              <IconWrapper>{section.icon}</IconWrapper>
              <SectionTitle>{section.title}</SectionTitle>
              <Text>{section.text}</Text>
              <Text className="korean">{section.koreanText}</Text>
            </Section>
          ))}
        </SectionContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

export default EducatorsPage;
