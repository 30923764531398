import React from "react";
import styled from "styled-components";
import logo from "../assets/images/MIRA_LOGO_SYMBOL.png";
import MiraSat from "../assets/Products/Orbit.png";
import { Link as RouterLink } from "react-router-dom";
import TickerText from "../components/TickerText";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  background-color: black;
  justify-content: space-between;
  color: white;
  padding: 0px 50px;
  height: 80vh;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    background-image: url(${MiraSat});
    background-size: cover;
    background-position: center;
    padding: 20px;
    height: 100vh;

    width: 90%;
  }
`;

const TextContainer = styled.div`
  max-width: 50%;
  padding-right: 0px;

  @media (max-width: 768px) {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 40px;

  hr {
    width: 300px;
    margin: 40px auto;
    border: none;
    height: 1px;
    background: linear-gradient(90deg, transparent, white, transparent);
  }

  @media (max-width: 768px) {
    font-size: 14px;

    hr {
      width: 300px;
      margin: 30px auto;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  overflow: hidden;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 10%;
  height: auto;
  /* margin-bottom: 50px; */

  @media (max-width: 768px) {
    width: 40%;
    margin-bottom: 20px;
  }
`;

const StyledImage = styled.img`
  width: auto;
  height: 500px;
  border-radius: 50px;
  margin: 0 auto;

  @media (max-width: 768px) {
    display: none;
  }
`;
// eslint-disable-next-line
const LinkOne = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: grey;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const StyledRouterLink = styled(RouterLink)`
  font-size: 14px;
  color: white;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: grey;
  }
`;

const Products: React.FC = () => {
  return (
    <>
      <TickerText direction="left" />
      <Container>
        <ImageContainer>
          <StyledImage src={MiraSat} alt="Mira Space Img" />
        </ImageContainer>
        <TextContainer>
          <Title>Our Products</Title>
          <StyledLogo src={logo} alt="Mira Space Logo" />
          <Paragraph>
            <br />
            Miraspace offers innovative satellite products that provide <br />
            hands-on learning experiences, allowing users to explore
            <br />
            space technology and enhance their understanding
            <br />
            of satellite systems.
            <br />
            <br />
            <hr />
            <br />
            미라스페이스는 위성 기술을 직접 체험할 수 있는 혁신적인 <br />
            위성 제품을 제공하여, 사용자들이 위성 시스템에 대한 <br />
            이해를 높이고 우주 기술을 탐구할 수 있도록 돕습니다.
            <br />
            <br />
            <StyledRouterLink to="/products">
              Explore All Products →
            </StyledRouterLink>
          </Paragraph>
        </TextContainer>
      </Container>
      <TickerText text="And Explore the Universe" direction="right" />
    </>
  );
};

export default Products;
